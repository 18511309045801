import React, { useState, useEffect } from "react";
import { Image, Button, Form } from "react-bootstrap";
import "../NewLanding/HomeBanner.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import { useTranslation } from "react-multi-lang";
import {
  getCurrenciesListStart,
  setTokenSellData,
} from "../../store/actions/BuyAction";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import {
  createKycApplicantStart,
  profileStart,
} from "../../store/actions/UserAction";
import KYCUpdateModal from "../KYC/KYCUpdateModal";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import configuration from "react-global-configuration";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";

const SellCryptoForm = ({ redirect = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("sell_crypto_form");
  const currencies = useSelector((state) => state.buy.currencies);
  const tokenSellData = useSelector((state) => state.buy.tokenSellData);
  const [cryptoCurrencyList, setCryptoCurrencyList] = useState([]);
  const updateTokenSellData = (data) => dispatch(setTokenSellData(data));
  const [skipRender, setSkipRender] = useState(true);
  const profile = useSelector((state) => state.user.profile);
  const kycApplicant = useSelector((state) => state.user.kycApplicant);
  const kycStatusUpdate = useSelector((state) => state.user.updateKYC);

  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [forexOptions, setForexOptions] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [selectedForex, setSelectedForex] = useState(null);
  const [kyc, setKyc] = useState(false);
  const wallet = useSelector((state) => state.wallet.walletData);
  const [rangeValue, setRangeValue] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? true
      : false
  );

  const closeKycModal = () => {
    setKyc(false);
  };

  useEffect(() => {
    dispatch(getCurrenciesListStart());
  }, []);

  const onTokenChange = (value) => {
    if (Number(value) == value) {
      updateTokenSellData({
        ...tokenSellData,
        from_amount: value,
      });
    }
  };

  const onComplete = () => {
    if (
      (!sessionStorage.getItem("userId") || !sessionStorage.getItem("token")) &&
      (!localStorage.getItem("userId") || !localStorage.getItem("token"))
    ) {
      navigate("/login");
    } else if (
      Object.keys(profile.data).length > 0 &&
      profile.data.kyc_verified == 0
    ) {
      dispatch(createKycApplicantStart());
    } else if (redirect && !tokenSellData.from_amount) {
      if (tokenSellData.step == 6) {
        updateTokenSellData({
          ...tokenSellData,
          tokens: tokenSellData.from_amount,
          from_currency: tokenSellData.from_currency,
          to_currency: tokenSellData.to_currency,
        });
      }
      navigate("/sell");
    } else if (redirect && tokenSellData.from_amount) {
      updateTokenSellData({ ...tokenSellData, step: 2 });
      navigate("/sell");
    } else {
      tokenSellData.from_amount > 0
        ? updateTokenSellData({
            ...tokenSellData,
            step: tokenSellData.step + 1,
          })
        : getErrorNotificationMessage("Please enter valid amount");
    }
  };

  const invalidAmount =
    tokenSellData.from_amount < tokenSellData.minAmount ||
    tokenSellData.from_amount == "" ||
    tokenSellData.from_amount <= 0;

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      const categories = currencies.data.crypto_currencies.map((item) => ({
        label: item.currency_code,
        value: item.id,
      }));
      setCategoriesOptions(categories);
      const selected_crypto = tokenSellData.token_type
        ? currencies.data.crypto_currencies.find(
            (category) => category.currency_code == tokenSellData.token_type
          )
        : currencies.data.crypto_currencies[0];

      setSelectedCrypto(
        tokenSellData.from_currency
          ? categories.find(
              (category) => category.label == tokenSellData.from_currency
            )
          : categories[0]
      );

      const forexCurrency = currencies.data.forex_currencies
        .filter((currency) => currency.id != selected_crypto?.id)
        .map((currency) => ({
          label: currency.currency_code,
          value: currency.id,
        }));

      const selected_currency = tokenSellData.token_type
        ? currencies.data.forex_currencies.find(
            (category) => category.currency_code == tokenSellData.token_type
          )
        : currencies.data.forex_currencies.filter(
            (currency) => currency.id != selected_crypto?.id
          )[0];

      setForexOptions(forexCurrency);
      setSelectedForex(
        tokenSellData.to_currency
          ? forexCurrency.find(
              (forex) => forex.label == tokenSellData.to_currency
            )
          : forexCurrency[0]
      );
      updateTokenSellData({
        ...tokenSellData,
        minAmount:
          parseInt(
            currencies.data?.crypto_currencies.filter(
              (data) => selected_crypto?.currency_code == data.currency_code
            )[0]?.min_sell_amount
          ) <= 0
            ? 0.0000001
            : parseInt(
                currencies.data?.crypto_currencies.filter(
                  (data) => selected_crypto?.currency_code == data.currency_code
                )[0]?.min_sell_amount
              ).toFixed(7),
        from_currency: selected_crypto.currency_code,
        to_currency: selected_currency.currency_code,
        from_exchange_rate: selected_crypto?.exchange_rate,
        from_source_currency: selected_crypto?.source_currency,
        to_exchange_rate: selected_currency?.exchange_rate,
        to_source_currency: selected_currency?.source_currency,
        commission: selected_currency?.sell_commission,
      });

      const cryptoExchangeUrl = configuration.get("configData.socket_url")!=""
        ? configuration.get("configData.socket_url")
        : "wss://cms-proexchange.rare-able.com:3091";

      const socket = new WebSocket(cryptoExchangeUrl);

      socket.onopen = () => {
        const subscribeMessage = JSON.stringify({
          action: "subscribe",
          asset: currencies.data?.crypto_currencies?.map(
            (currency) => currency.currency_code
          ),
        });
        socket.send(subscribeMessage);
      };

      socket.onmessage = (event) => {
        try {
          const parsedData = JSON.parse(event.data);

          parsedData.map(([asset, value]) => ({
            asset,
            value,
          }));
          setCryptoCurrencyList(
            parsedData.map(([asset, value]) => ({
              asset,
              value,
            }))
          );
        } catch (error) {}
      };
      return () => {
        if (socket) {
          socket.close();
        }
      };
    }
    setSkipRender(false);
  }, [currencies]);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#010101",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 200,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid transparent!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      minWidth: "100px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#010101",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#298bff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#298bff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#010101",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#fffaf0" : "#fff",
        color: "#000",
      };
    },
  };

  const onCryptoChange = (selectedOption) => {
    const crypto = currencies.data.crypto_currencies.find(
      (crypto) => crypto.id == selectedOption.value
    );

    if (Object.keys(wallet.data).length > 0) {
      setWalletBalance(
        wallet.data?.crypto_wallets.find(
          (data) => data.currency_code == selectedOption.label
        )?.remaining_original
      );
      const percentage =
        wallet.data?.crypto_wallets.find(
          (data) => data.currency_code == selectedOption.label
        )?.remaining_original > 0 &&
        (parseFloat(tokenSellData.from_amount) /
          wallet.data?.crypto_wallets.find(
            (data) => data.currency_code == selectedOption.label
          )?.remaining_original) *
          100;

      setRangeValue(
        isNaN(percentage) ? 0 : Math.min(percentage, 100).toFixed(2)
      );
    }

    updateTokenSellData({
      ...tokenSellData,
      minAmount:
        parseInt(
          currencies.data?.crypto_currencies.filter(
            (data) => selectedOption?.label == data.currency_code
          )[0]?.min_sell_amount
        ) <= 0
          ? 0.0000001
          : parseInt(
              currencies.data?.crypto_currencies.filter(
                (data) => selectedOption?.label == data.currency_code
              )[0]?.min_sell_amount
            ).toFixed(7),
      from_currency: selectedOption.label,
      from_exchange_rate: crypto.exchange_rate,
      from_source_currency: crypto.source_currency,
    });
    setSelectedCrypto(selectedOption);
  };

  const onCurrencyChange = (selectedOption) => {
    const forex = currencies.data.currencies.find(
      (forex) => forex.id == selectedOption.value
    );
    updateTokenSellData({
      ...tokenSellData,
      to_exchange_rate: forex.exchange_rate,
      to_source_currency: forex.source_currency,
      to_currency: selectedOption.label,
      commission: forex.sell_commission,
    });
    setSelectedForex(selectedOption);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !kycApplicant.loading &&
      Object.keys(kycApplicant.data).length > 0
    ) {
      kycApplicant.data?.link && kycApplicant.data?.link !== ""
        ? window.open(kycApplicant.data?.link, "_blank")
        : dispatch(profileStart());
    }
    setSkipRender(false);
  }, [kycApplicant]);

  useEffect(() => {
    if (
      !skipRender &&
      !kycStatusUpdate.loading &&
      Object.keys(kycStatusUpdate.data).length > 0
    ) {
      closeKycModal();
      window.location.reload();
    }
    setSkipRender(false);
  }, [kycStatusUpdate]);

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      !wallet.loading &&
      Object.keys(currencies.data).length > 0 &&
      Object.keys(wallet.data).length > 0
    ) {
      const selected_crypto = tokenSellData.token_type
        ? currencies.data.crypto_currencies.find(
            (category) => category.currency_code == tokenSellData.token_type
          )
        : currencies.data.crypto_currencies[0];
      setWalletBalance(
        wallet.data?.crypto_wallets.find(
          (data) => data.currency_code == selected_crypto?.currency_code
        )?.remaining_original
      );
    }
    setSkipRender(false);
  }, [currencies, wallet]);

  useEffect(() => {
    if (localStorage.getItem("token") || sessionStorage.getItem("token"))
      dispatch(fetchWalletDetailsStart());
  }, []);

  return (
    <>
      {currencies.loading ? (
        <div className="efi-exchange-swap-full-frame">
          <div className="efi-exchange-input-wrapped">
            <div className="efi-exchange-fields-card">
              {[...Array(2)].map((i) => (
                <Skeleton count={1} borderRadius={5} height={160} />
              ))}
            </div>
            <div className="efi-exchange-fields-card">
              {[...Array(2)].map((i) => (
                <Skeleton
                  count={1}
                  className="mt-3"
                  borderRadius={5}
                  height={160}
                />
              ))}
            </div>
            <div className="efi-swap-icons">
              <Skeleton count={1} circle={true} width={70} height={70} />
            </div>
          </div>
          <Skeleton count={1} height={50} />
          <div className="efi-swap-action">
            <Skeleton count={1} height={50} borderRadius={10} />
          </div>
          <div className="d-flex justify-content-center align-item-center ">
            <Skeleton count={1} height={22} width={100} borderRadius={5} />
          </div>
        </div>
      ) : Object.keys(currencies.data).length > 0 ? (
        <div className="efi-exchange-swap-full-frame">
          <div className="efi-exchange-input-wrapped">
            <div className="efi-exchange-fields-card">
              <div className="efi-exchange-fields-enter">
                <div className="efi-fields-label-enter">{t("send")}</div>
                <Form>
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      value={tokenSellData.from_amount}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const remainingOriginal = walletBalance || 0;

                        const percentage =
                          (parseFloat(newValue) / remainingOriginal) * 100;
                        setRangeValue(
                          isNaN(percentage)
                            ? 0
                            : Math.min(percentage, 100).toFixed(2)
                        );
                        onTokenChange(
                          newValue <= 100000
                            ? newValue
                            : tokenSellData.from_amount
                        );
                      }}
                    />
                  </Form.Group>
                  {(localStorage.getItem("token") ||
                    sessionStorage.getItem("token")) && (
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="trade-percent-range mt-2 mb-2"
                    >
                      <div className="range-label">
                      <span>{rangeValue}%</span>
                      <span>100%</span>
                    </div>
                      <Form.Range
                        disabled={!walletBalance || walletBalance <= 0}
                        min="0"
                        max="100"
                        value={rangeValue}
                        onChange={(e) => {
                          const data =
                            (parseFloat(e.target.value) / 100) *
                            (wallet.data?.user_wallets ? walletBalance : 0);
                          setRangeValue(e.target.value);
                          onTokenChange(data);
                        }}
                      />
                      <div className="trade-range-value">
                        {rangeValue} % / 100 %
                      </div>
                    </Form.Group>
                  )}
                  {(localStorage.getItem("token") ||
                    sessionStorage.getItem("token")) && (
                    <div className="trade-stock-max">
                      <div className="trade-stock-max-info-sec">
                        <div className="trade-stock-max-info">
                          Available:
                          <span>{parseFloat(walletBalance)}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
              {currencies.data.crypto_currencies.length > 0 && (
                <div className="efi-exchange-fields-type">
                  <div className="efi-fields-label">
                    <CustomLazyLoad
                      src={
                        tokenSellData.from_currency
                          ? currencies.data.crypto_currencies.find(
                              (item) =>
                                item.currency_code ==
                                tokenSellData.from_currency
                            ).picture
                          : currencies.data.crypto_currencies[0].picture
                      }
                      className="swap-icon crypto-icon"
                    />
                    <span>
                      {tokenSellData.from_currency
                        ? currencies.data.crypto_currencies.find(
                            (item) =>
                              item.currency_code == tokenSellData.from_currency
                          ).name
                        : currencies.data.crypto_currencies[0].name}
                    </span>
                  </div>
                  <Select
                    className="fillert-drop"
                    isSearchable={false}
                    options={categoriesOptions?.filter(
                      (data) => data?.value != selectedForex?.value
                    )}
                    styles={customStyles}
                    value={selectedCrypto}
                    onChange={(selectedOption) =>
                      onCryptoChange(selectedOption)
                    }
                  />
                </div>
              )}
            </div>
            <div className="efi-exchange-fields-card">
              <div className="efi-exchange-fields-enter">
                <div className="efi-fields-label-enter">{t("recieve")}</div>
                <div className="efi-receive-text">
                  {tokenSellData.from_amount > 0
                    ? !isNaN(
                        (
                          tokenSellData.from_amount *
                            (cryptoCurrencyList?.filter(
                              (data) =>
                                tokenSellData.from_currency == data.asset
                            ))[0]?.value *
                            tokenSellData.to_exchange_rate -
                          (tokenSellData.commission *
                            tokenSellData.from_amount *
                            (cryptoCurrencyList?.filter(
                              (data) =>
                                tokenSellData.from_currency == data.asset
                            ))[0]?.value *
                            tokenSellData.to_exchange_rate) /
                            100
                        ).toFixed(7)
                      )
                      ? (
                          tokenSellData.from_amount *
                            (cryptoCurrencyList?.filter(
                              (data) =>
                                tokenSellData.from_currency == data.asset
                            ))[0]?.value *
                            tokenSellData.to_exchange_rate -
                          (tokenSellData.commission *
                            tokenSellData.from_amount *
                            (cryptoCurrencyList?.filter(
                              (data) =>
                                tokenSellData.from_currency == data.asset
                            ))[0]?.value *
                            tokenSellData.to_exchange_rate) /
                            100
                        ).toFixed(7)
                      : "0.0000"
                    : "0.0000"}
                </div>
              </div>
              {currencies.data.currencies.length > 0 && (
                <div className="efi-exchange-fields-type">
                  <div className="efi-fields-label">
                    <CustomLazyLoad
                      src={
                        tokenSellData.to_currency
                          ? currencies.data.currencies.find(
                              (item) =>
                                item.currency_code == tokenSellData.to_currency
                            ).picture
                          : currencies.data.currencies[0].picture
                      }
                      className="swap-icon crypto-icon"
                    />
                    <span>
                      {tokenSellData.to_currency
                        ? currencies.data.currencies.find(
                            (item) =>
                              item.currency_code == tokenSellData.to_currency
                          ).name
                        : currencies.data.currencies[0].name}
                    </span>
                  </div>
                  <Select
                    className="fillert-drop"
                    isSearchable={false}
                    options={
                      selectedCrypto
                        ? forexOptions.filter(
                            (data) => data.value != selectedCrypto.value
                          )
                        : forexOptions.filter(
                            (data) => data.value != categoriesOptions[0].value
                          )
                    }
                    styles={customStyles}
                    value={selectedForex}
                    onChange={(selectedOption) =>
                      onCurrencyChange(selectedOption)
                    }
                  />
                </div>
              )}
            </div>
            <div className={`efi-swap-icons ${isLoggedIn?" top-space ":""}`}>
              <Image
                src={window.location.origin + "/img/icon/swap.svg"}
                className="swap-icon"
              />
            </div>
          </div>
          {invalidAmount && (
            <p className="error-msg text-danger select-date buy-form">
              {t("invalid", { value: tokenSellData.minAmount.toFixed(7) })}
            </p>
          )}
          {tokenSellData.from_amount > 100000 && (
            <p className="error-msg text-danger select-date buy-form">
              {" "}
              {t("max_invalid", { value: 100000 })}
              {/* max amount validation data come from api in future */}
            </p>
          )}
          <div className="efi-swap-exchange-info efi-info-frame">
            <p>
              {" "}
              <span> {t("estimated_rate")} </span>{" "}
              <span>
                {" "}
                1 {""}
                {tokenSellData.from_currency
                  ? currencies.data.crypto_currencies.find(
                      (item) =>
                        item.currency_code == tokenSellData.from_currency
                    ).currency_code
                  : currencies.data.crypto_currencies[0].currency_code}{" "}
                = {""}
                {!isNaN(
                  (cryptoCurrencyList?.filter(
                    (data) => tokenSellData.from_currency == data.asset
                  ))[0]?.value *
                    tokenSellData.to_exchange_rate -
                    (tokenSellData.commission *
                      (cryptoCurrencyList?.filter(
                        (data) => tokenSellData.from_currency == data.asset
                      ))[0]?.value *
                      tokenSellData.to_exchange_rate) /
                      100
                )
                  ? (
                      (cryptoCurrencyList?.filter(
                        (data) => tokenSellData.from_currency == data.asset
                      ))[0]?.value *
                        tokenSellData.to_exchange_rate -
                      (tokenSellData.commission *
                        (cryptoCurrencyList?.filter(
                          (data) => tokenSellData.from_currency == data.asset
                        ))[0]?.value *
                        tokenSellData.to_exchange_rate) /
                        100
                    ).toFixed(7)
                  : "0.0000"}
                {""}{" "}
                {tokenSellData.to_currency
                  ? currencies.data.currencies.find(
                      (item) => item.currency_code == tokenSellData.to_currency
                    ).currency_code
                  : currencies.data.forex_currencies[0].currency_code}{" "}
              </span>
            </p>
          </div>
          <div className="efi-swap-action">
            <Button
              className="action-btn primary w-100 item_flex_x_5 justify-content-center"
              onClick={onComplete}
              disabled={
                invalidAmount ||
                kycApplicant.buttonDisable ||
                tokenSellData.from_amount > 100000
              }
            >
              {redirect ? t("exchange") : t("continue")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#171717"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M18.01 19.99A9.964 9.964 0 0112 22c-5.52 0-8.89-5.56-8.89-5.56m0 0h4.52m-4.52 0v5M22 12c0 1.82-.49 3.53-1.34 5M6.03 3.97A9.921 9.921 0 0112 2c6.67 0 10 5.56 10 5.56m0 0v-5m0 5h-4.44M2 12c0-1.82.48-3.53 1.33-5"
                ></path>
              </svg>
            </Button>
          </div>
          <div className="efi-swap-exchange-info">
            <p>{t("no_extra_fees")}</p>
          </div>
        </div>
      ) : null}
      {kyc && <KYCUpdateModal kyc={kyc} closeKyc={closeKycModal} />}
    </>
  );
};

export default SellCryptoForm;
