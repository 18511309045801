import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  createKycApplicantStart,
  profileStart,
} from "../../store/actions/UserAction";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Offcanvas,
  Button,
  Dropdown,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import configuration from "react-global-configuration";
import useLogout from "../layouts/Header/useLogout";
import LogoutModal from "../Profile/LogoutModal";
import {
  getCryptoCurrency,
  getCurrenciesListStart,
} from "../../store/actions/BuyAction";

const NewLandingHeader = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const t = useTranslation("header");
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") || sessionStorage.getItem("token")
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const login = useSelector((state) => state.user.login);
  const profile = useSelector((state) => state.user.profile);
  const [skipRender, setSkipRender] = useState(true);
  const kycApplicant = useSelector((state) => state.user.kycApplicant);
  const [logoutShow, setLogoutShow] = useState(false);
  const currencies = useSelector((state) => state.buy.currencies);

  const handleDropdownToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    (localStorage.getItem("token") || sessionStorage.getItem("token")) &&
      dispatch(profileStart());
  }, []);

  const handleLogout = () => {
    setLogoutShow(true);
  };

  useEffect(() => {
    if (!skipRender && !login.loading && Object.keys(login.data).length > 0) {
      setIsLoggedIn(localStorage.getItem("token"));
    }
    setSkipRender(false);
  }, [login]);

  const verifyKyc = () => {
    dispatch(createKycApplicantStart());
  };

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      const cryptoExchangeUrl = configuration.get("configData.socket_url")!=""
        ? configuration.get("configData.socket_url")
        : "wss://cms-proexchange.rare-able.com:3091";

      const socket = new WebSocket(cryptoExchangeUrl);

      socket.onopen = () => {
        const subscribeMessage = JSON.stringify({
          action: "subscribe",
          asset: currencies.data?.crypto_currencies?.map(
            (currency) => currency.currency_code
          ),
        });
        socket.send(subscribeMessage);
      };

      socket.onmessage = (event) => {
        try {
          const parsedData = JSON.parse(event.data);

          parsedData.map(([asset, value]) => ({
            asset,
            value,
          }));
          dispatch(
            getCryptoCurrency(
              parsedData.map(([asset, value]) => ({
                asset,
                value,
              }))
            )
          );
        } catch (error) {}
      };
      return () => {
        if (socket) {
          socket.close();
        }
      };
    }
    setSkipRender(false);
  }, [currencies]);

  useEffect(() => {
    if (Object.keys(currencies.data).length <= 0) {
      dispatch(getCurrenciesListStart());
    }
  }, []);

  return (
    <>
      <header className="header-nav-center-1">
        <Navbar expand="lg">
          <Container fluid className="header-padding">
            <Link to="/" className="navbar-brand">
              <Image
                className="logo"
                src={configuration.get("configData.site_logo")}
                type="image/png"
              />
            </Link>
            <Navbar.Collapse className="nav-menu-bar">
              <Nav className="ms-auto">
                <Link
                  className={`nav-link ${
                    location.pathname === "/buy" ? "active" : ""
                  }`}
                  to="/buy"
                >
                  {t("buy_swap_crypto")}
                </Link>
                <Link
                  className={`nav-link ${
                    location.pathname === "/sell" ? "active" : ""
                  }`}
                  to="/sell"
                >
                  {t("sell_crypto")}
                </Link>
                <Link
                  className={`nav-link ${
                    location.pathname === "/trading" ? "active" : ""
                  }`}
                  to="/trading"
                >
                  Trade
                </Link>
                {/* {<Link className={`nav-link ${location.pathname === "/beneficiaries" ? "active" : ""}`}
                  to={isLoggedIn ? "/beneficiaries" : "/login"}>{t("iban_transfer")}</Link>} */}
                <Link
                  className={`nav-link ${
                    location.pathname === "/page/about-us" ? "active" : ""
                  }`}
                  to="/page/about-us"
                >
                  {t("about_us")}
                </Link>
                <Link
                  className={`nav-link ${
                    location.pathname === "/page/help" ? "active" : ""
                  }`}
                  to="/page/help"
                >
                  {t("help_center")}
                </Link>
              </Nav>
              <Nav className="ms-1 me-1">
                {profile.data?.onboarding_completed == 0 && (
                  <Link
                    className="action-btn overlay-primary "
                    to="/onboarding"
                  >
                    Initiate Onboarding
                  </Link>
                )}
                {profile.data?.kyc_verified == 0 &&
                  profile.data?.onboarding_completed == 1 && (
                    <Link
                      className="action-btn primary"
                      to="#"
                      onClick={verifyKyc}
                    >
                      {kycApplicant.buttonDisable ? "Loading" : "Verify KYC"}
                    </Link>
                  )}
              </Nav>
              <Nav className="">
                {!isLoggedIn ? (
                  <Link
                    to="/login"
                    className="nav-link new-default-secondary-btn"
                  >
                    {t("login_signup")}
                  </Link>
                ) : !profile.loading && Object.keys(profile.data).length > 0 ? (
                  <div className="user-dropdown-sec">
                    <Dropdown
                      onToggle={handleDropdownToggle}
                      show={dropdownOpen}
                    >
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="landing-header"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#171717"
                            d="M12.12 13.53h-.17c-2.27-.07-3.97-1.84-3.97-4.02 0-2.22 1.81-4.03 4.03-4.03s4.03 1.81 4.03 4.03a4.014 4.014 0 01-3.89 4.02h-.03zM12 6.97c-1.4 0-2.53 1.14-2.53 2.53a2.52 2.52 0 002.43 2.53.84.84 0 01.23 0 2.535 2.535 0 002.4-2.53c0-1.39-1.13-2.53-2.53-2.53zM12 22.75c-2.69 0-5.26-1-7.25-2.82a.755.755 0 01-.24-.63c.13-1.19.87-2.3 2.1-3.12 2.98-1.98 7.81-1.98 10.78 0 1.23.83 1.97 1.93 2.1 3.12.03.24-.06.47-.24.63A10.71 10.71 0 0112 22.75zM6.08 19.1A9.208 9.208 0 0012 21.25c2.17 0 4.26-.76 5.92-2.15-.18-.61-.66-1.2-1.37-1.68-2.46-1.64-6.63-1.64-9.11 0-.71.48-1.18 1.07-1.36 1.68z"
                          ></path>
                          <path
                            fill="#171717"
                            d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12S6.07 1.25 12 1.25 22.75 6.07 22.75 12 17.93 22.75 12 22.75zm0-20C6.9 2.75 2.75 6.9 2.75 12S6.9 21.25 12 21.25s9.25-4.15 9.25-9.25S17.1 2.75 12 2.75z"
                          ></path>
                        </svg>
                        <span>{profile.data.name}</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link
                          to="/profile"
                          onClick={() => handleDropdownClose()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#171717"
                              d="M12.16 11.62h-.08a.543.543 0 00-.18 0C9 11.53 6.81 9.25 6.81 6.44c0-2.86 2.33-5.19 5.19-5.19 2.86 0 5.19 2.33 5.19 5.19-.01 2.81-2.21 5.09-5 5.18h-.03zM12 2.75a3.7 3.7 0 00-3.69 3.69c0 2 1.56 3.61 3.55 3.68.05-.01.19-.01.32 0 1.96-.09 3.5-1.7 3.51-3.68A3.7 3.7 0 0012 2.75zM12.17 22.55c-1.96 0-3.93-.5-5.42-1.5-1.39-.92-2.15-2.18-2.15-3.55 0-1.37.76-2.64 2.15-3.57 3-1.99 7.86-1.99 10.84 0 1.38.92 2.15 2.18 2.15 3.55 0 1.37-.76 2.64-2.15 3.57-1.5 1-3.46 1.5-5.42 1.5zm-4.59-7.36c-.96.64-1.48 1.46-1.48 2.32 0 .85.53 1.67 1.48 2.3 2.49 1.67 6.69 1.67 9.18 0 .96-.64 1.48-1.46 1.48-2.32 0-.85-.53-1.67-1.48-2.3-2.49-1.66-6.69-1.66-9.18 0z"
                            ></path>
                          </svg>
                          <span>{t("profile")}</span>
                        </Link>
                        {profile.data?.onboarding_completed == 1 && (
                          <Link
                            to="/wallet-currency-list"
                            onClick={() => handleDropdownClose()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 25 24"
                            >
                              <path
                                fill="#171717"
                                d="M13.042 9.75h-6c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h6c.41 0 .75.34.75.75s-.34.75-.75.75zM19.082 14.8c-1.51 0-2.79-1.12-2.91-2.56-.08-.83.22-1.64.82-2.23.5-.52 1.21-.81 1.96-.81h2.09c.99.03 1.75.81 1.75 1.77v2.06c0 .96-.76 1.74-1.72 1.77h-1.99zm1.93-4.1h-2.05c-.35 0-.67.13-.9.37-.29.28-.43.66-.39 1.04.05.66.69 1.19 1.41 1.19h1.96c.13 0 .25-.12.25-.27v-2.06c0-.15-.12-.26-.28-.27z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M16.042 21.25h-9c-3.44 0-5.75-2.31-5.75-5.75v-7c0-3.08 1.9-5.31 4.85-5.68.27-.04.58-.07.9-.07h9c.24 0 .55.01.87.06 2.95.34 4.88 2.58 4.88 5.69v1.45c0 .41-.34.75-.75.75h-2.08c-.35 0-.67.13-.9.37l-.01.01c-.28.27-.41.64-.38 1.02.05.66.69 1.19 1.41 1.19h1.96c.41 0 .75.34.75.75v1.45c0 3.45-2.31 5.76-5.75 5.76zm-9-17c-.24 0-.47.02-.7.05-2.2.28-3.55 1.88-3.55 4.2v7c0 2.58 1.67 4.25 4.25 4.25h9c2.58 0 4.25-1.67 4.25-4.25v-.7h-1.21c-1.51 0-2.79-1.12-2.91-2.56-.08-.82.22-1.64.82-2.22.52-.53 1.22-.82 1.97-.82h1.33v-.7c0-2.34-1.37-3.95-3.59-4.21-.24-.04-.45-.04-.66-.04h-9z"
                              ></path>
                            </svg>
                            <span>{t("wallet")}</span>
                          </Link>
                        )}
                        {profile.data?.onboarding_completed == 1 && (
                          <Link
                            to="/recipient-list"
                            onClick={() => handleDropdownClose()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#171717"
                                d="M21 11.75H3c-.96 0-1.75-.79-1.75-1.75V6.68c0-.68.47-1.37 1.1-1.62l9-3.6c.38-.15.92-.15 1.3 0l9 3.6c.63.25 1.1.95 1.1 1.62V10c0 .96-.79 1.75-1.75 1.75zm-9-8.91c-.04 0-.08 0-.1.01l-8.99 3.6c-.06.03-.16.16-.16.23V10c0 .14.11.25.25.25h18c.14 0 .25-.11.25-.25V6.68c0-.07-.09-.2-.16-.23l-9-3.6c-.02-.01-.05-.01-.09-.01zM22 22.75H2c-.41 0-.75-.34-.75-.75v-3c0-.96.79-1.75 1.75-1.75h18c.96 0 1.75.79 1.75 1.75v3c0 .41-.34.75-.75.75zm-19.25-1.5h18.5V19c0-.14-.11-.25-.25-.25H3c-.14 0-.25.11-.25.25v2.25z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M4 18.75c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75zM8 18.75c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75zM12 18.75c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75zM16 18.75c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75zM20 18.75c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75zM23 22.75H1c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h22c.41 0 .75.34.75.75s-.34.75-.75.75zM12 9.25c-1.24 0-2.25-1.01-2.25-2.25S10.76 4.75 12 4.75 14.25 5.76 14.25 7 13.24 9.25 12 9.25zm0-3c-.41 0-.75.34-.75.75s.34.75.75.75.75-.34.75-.75-.34-.75-.75-.75z"
                              ></path>
                            </svg>
                            <span>{t("bank_accounts")}</span>
                          </Link>
                        )}
                        {/* <Link to="/beneficiaries" onClick={() => handleDropdownClose()}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M23 11h-2V9a1 1 0 00-2 0v2h-2a1 1 0 000 2h2v2a1 1 0 002 0v-2h2a1 1 0 000-2zM9 12a6 6 0 10-6-6 6.006 6.006 0 006 6zM9 2a4 4 0 11-4 4 4 4 0 014-4zM9 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                            </svg>
                            <span>{t("beneficiaries")}</span>
                          </Link> */}
                        {profile.data?.onboarding_completed == 1 && (
                          <Link
                            to="/transactions"
                            onClick={() => handleDropdownClose()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 25 24"
                            >
                              <path
                                fill="#171717"
                                d="M12.018 22.75c-.78 0-1.54-.4-2.06-1.1l-1.01-1.35c-.21-.28-.49-.44-.79-.46-.3-.01-.6.12-.84.37l-.55-.51.55.51c-1.44 1.54-2.55 1.42-3.08 1.21-.54-.21-1.45-.9-1.45-3.12V7.04c0-4.44 1.28-5.79 5.47-5.79h7.56c4.19 0 5.47 1.35 5.47 5.79V18.3c0 2.21-.91 2.9-1.45 3.12-.53.21-1.63.33-3.08-1.21-.24-.26-.54-.39-.85-.37-.3.02-.59.18-.8.46l-1.01 1.35c-.54.7-1.3 1.1-2.08 1.1zm-3.92-4.42h.13c.74.04 1.44.43 1.91 1.06l1.01 1.35c.49.65 1.24.65 1.73 0l1.01-1.35c.48-.63 1.17-1.02 1.92-1.06.75-.04 1.48.27 2.02.85.76.81 1.25.91 1.43.84.24-.1.5-.68.5-1.72V7.04c0-3.61-.63-4.29-3.97-4.29h-7.56c-3.34 0-3.97.68-3.97 4.29V18.3c0 1.05.26 1.63.5 1.72.17.07.67-.03 1.43-.84.55-.55 1.22-.85 1.91-.85z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M14.768 10.75h-5.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.5c.41 0 .75.34.75.75s-.34.75-.75.75z"
                              ></path>
                            </svg>
                            <span>{t("transactions")}</span>
                          </Link>
                        )}
                        <Link to="#" onClick={handleLogout}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#171717"
                              d="M15.24 22.27h-.13c-4.44 0-6.58-1.75-6.95-5.67-.04-.41.26-.78.68-.82.41-.04.78.27.82.68.29 3.14 1.77 4.31 5.46 4.31h.13c4.07 0 5.51-1.44 5.51-5.51V8.74c0-4.07-1.44-5.51-5.51-5.51h-.13c-3.71 0-5.19 1.19-5.46 4.39-.05.41-.39.72-.82.68a.751.751 0 01-.69-.81c.34-3.98 2.49-5.76 6.96-5.76h.13c4.91 0 7.01 2.1 7.01 7.01v6.52c0 4.91-2.1 7.01-7.01 7.01z"
                            ></path>
                            <path
                              fill="#171717"
                              d="M14.88 12.75H2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h12.88a.749.749 0 110 1.5z"
                            ></path>
                            <path
                              fill="#171717"
                              d="M12.65 16.1c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06L14.94 12l-2.82-2.82a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l3.35 3.35c.29.29.29.77 0 1.06l-3.35 3.35c-.15.15-.34.22-.53.22z"
                            ></path>
                          </svg>
                          <span>{t("logout")}</span>
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : null}
              </Nav>
            </Navbar.Collapse>
            <div className="nav-menu-repo">
              <Button variant="primary" onClick={handleShow}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M21 6.75H8a.75.75 0 010-1.5h13a.75.75 0 010 1.5zm.75 5.25a.75.75 0 00-.75-.75H3a.75.75 0 000 1.5h18a.75.75 0 00.75-.75zm0 6a.75.75 0 00-.75-.75h-9a.75.75 0 000 1.5h9a.75.75 0 00.75-.75z"
                    data-original="#000000"
                  ></path>
                </svg>
              </Button>
              <div className="header-right-wrap">
                <div className="nav-item">
                  {!isLoggedIn ? (
                    <Link
                      to="/login"
                      className={`nav-link ${
                        location.pathname === "/login" ? "active" : ""
                      }`}
                    >
                      {t("login")}
                    </Link>
                  ) : !profile.loading &&
                    Object.keys(profile.data).length > 0 ? (
                    <div className="user-dropdown-sec">
                      <Dropdown
                        onToggle={handleDropdownToggle}
                        show={dropdownOpen}
                      >
                        <Dropdown.Toggle id="dropdown-basic">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#171717"
                              d="M12.12 13.53h-.17c-2.27-.07-3.97-1.84-3.97-4.02 0-2.22 1.81-4.03 4.03-4.03s4.03 1.81 4.03 4.03a4.014 4.014 0 01-3.89 4.02h-.03zM12 6.97c-1.4 0-2.53 1.14-2.53 2.53a2.52 2.52 0 002.43 2.53.84.84 0 01.23 0 2.535 2.535 0 002.4-2.53c0-1.39-1.13-2.53-2.53-2.53zM12 22.75c-2.69 0-5.26-1-7.25-2.82a.755.755 0 01-.24-.63c.13-1.19.87-2.3 2.1-3.12 2.98-1.98 7.81-1.98 10.78 0 1.23.83 1.97 1.93 2.1 3.12.03.24-.06.47-.24.63A10.71 10.71 0 0112 22.75zM6.08 19.1A9.208 9.208 0 0012 21.25c2.17 0 4.26-.76 5.92-2.15-.18-.61-.66-1.2-1.37-1.68-2.46-1.64-6.63-1.64-9.11 0-.71.48-1.18 1.07-1.36 1.68z"
                            ></path>
                            <path
                              fill="#171717"
                              d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12S6.07 1.25 12 1.25 22.75 6.07 22.75 12 17.93 22.75 12 22.75zm0-20C6.9 2.75 2.75 6.9 2.75 12S6.9 21.25 12 21.25s9.25-4.15 9.25-9.25S17.1 2.75 12 2.75z"
                            ></path>
                          </svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Link
                            to="/profile"
                            onClick={() => handleDropdownClose()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#171717"
                                d="M12.16 11.62h-.08a.543.543 0 00-.18 0C9 11.53 6.81 9.25 6.81 6.44c0-2.86 2.33-5.19 5.19-5.19 2.86 0 5.19 2.33 5.19 5.19-.01 2.81-2.21 5.09-5 5.18h-.03zM12 2.75a3.7 3.7 0 00-3.69 3.69c0 2 1.56 3.61 3.55 3.68.05-.01.19-.01.32 0 1.96-.09 3.5-1.7 3.51-3.68A3.7 3.7 0 0012 2.75zM12.17 22.55c-1.96 0-3.93-.5-5.42-1.5-1.39-.92-2.15-2.18-2.15-3.55 0-1.37.76-2.64 2.15-3.57 3-1.99 7.86-1.99 10.84 0 1.38.92 2.15 2.18 2.15 3.55 0 1.37-.76 2.64-2.15 3.57-1.5 1-3.46 1.5-5.42 1.5zm-4.59-7.36c-.96.64-1.48 1.46-1.48 2.32 0 .85.53 1.67 1.48 2.3 2.49 1.67 6.69 1.67 9.18 0 .96-.64 1.48-1.46 1.48-2.32 0-.85-.53-1.67-1.48-2.3-2.49-1.66-6.69-1.66-9.18 0z"
                              ></path>
                            </svg>
                            <span>{t("profile")}</span>
                          </Link>
                          {profile.data?.onboarding_completed == 1 && (
                            <Link
                              to="/wallet-currency-list"
                              onClick={() => handleDropdownClose()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 25 24"
                              >
                                <path
                                  fill="#171717"
                                  d="M13.042 9.75h-6c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h6c.41 0 .75.34.75.75s-.34.75-.75.75zM19.082 14.8c-1.51 0-2.79-1.12-2.91-2.56-.08-.83.22-1.64.82-2.23.5-.52 1.21-.81 1.96-.81h2.09c.99.03 1.75.81 1.75 1.77v2.06c0 .96-.76 1.74-1.72 1.77h-1.99zm1.93-4.1h-2.05c-.35 0-.67.13-.9.37-.29.28-.43.66-.39 1.04.05.66.69 1.19 1.41 1.19h1.96c.13 0 .25-.12.25-.27v-2.06c0-.15-.12-.26-.28-.27z"
                                ></path>
                                <path
                                  fill="#171717"
                                  d="M16.042 21.25h-9c-3.44 0-5.75-2.31-5.75-5.75v-7c0-3.08 1.9-5.31 4.85-5.68.27-.04.58-.07.9-.07h9c.24 0 .55.01.87.06 2.95.34 4.88 2.58 4.88 5.69v1.45c0 .41-.34.75-.75.75h-2.08c-.35 0-.67.13-.9.37l-.01.01c-.28.27-.41.64-.38 1.02.05.66.69 1.19 1.41 1.19h1.96c.41 0 .75.34.75.75v1.45c0 3.45-2.31 5.76-5.75 5.76zm-9-17c-.24 0-.47.02-.7.05-2.2.28-3.55 1.88-3.55 4.2v7c0 2.58 1.67 4.25 4.25 4.25h9c2.58 0 4.25-1.67 4.25-4.25v-.7h-1.21c-1.51 0-2.79-1.12-2.91-2.56-.08-.82.22-1.64.82-2.22.52-.53 1.22-.82 1.97-.82h1.33v-.7c0-2.34-1.37-3.95-3.59-4.21-.24-.04-.45-.04-.66-.04h-9z"
                                ></path>
                              </svg>
                              <span>{t("wallet")}</span>
                            </Link>
                          )}
                          {profile.data?.onboarding_completed == 1 && (
                            <Link
                              to="/recipient-list"
                              onClick={() => handleDropdownClose()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="#171717"
                                  d="M21 11.75H3c-.96 0-1.75-.79-1.75-1.75V6.68c0-.68.47-1.37 1.1-1.62l9-3.6c.38-.15.92-.15 1.3 0l9 3.6c.63.25 1.1.95 1.1 1.62V10c0 .96-.79 1.75-1.75 1.75zm-9-8.91c-.04 0-.08 0-.1.01l-8.99 3.6c-.06.03-.16.16-.16.23V10c0 .14.11.25.25.25h18c.14 0 .25-.11.25-.25V6.68c0-.07-.09-.2-.16-.23l-9-3.6c-.02-.01-.05-.01-.09-.01zM22 22.75H2c-.41 0-.75-.34-.75-.75v-3c0-.96.79-1.75 1.75-1.75h18c.96 0 1.75.79 1.75 1.75v3c0 .41-.34.75-.75.75zm-19.25-1.5h18.5V19c0-.14-.11-.25-.25-.25H3c-.14 0-.25.11-.25.25v2.25z"
                                ></path>
                                <path
                                  fill="#171717"
                                  d="M4 18.75c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75zM8 18.75c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75zM12 18.75c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75zM16 18.75c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75zM20 18.75c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75zM23 22.75H1c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h22c.41 0 .75.34.75.75s-.34.75-.75.75zM12 9.25c-1.24 0-2.25-1.01-2.25-2.25S10.76 4.75 12 4.75 14.25 5.76 14.25 7 13.24 9.25 12 9.25zm0-3c-.41 0-.75.34-.75.75s.34.75.75.75.75-.34.75-.75-.34-.75-.75-.75z"
                                ></path>
                              </svg>
                              <span>{t("bank_accounts")}</span>
                            </Link>
                          )}
                          {profile.data?.onboarding_completed == 1 && (
                            <Link
                              to="/transactions"
                              onClick={() => handleDropdownClose()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 25 24"
                              >
                                <path
                                  fill="#171717"
                                  d="M12.018 22.75c-.78 0-1.54-.4-2.06-1.1l-1.01-1.35c-.21-.28-.49-.44-.79-.46-.3-.01-.6.12-.84.37l-.55-.51.55.51c-1.44 1.54-2.55 1.42-3.08 1.21-.54-.21-1.45-.9-1.45-3.12V7.04c0-4.44 1.28-5.79 5.47-5.79h7.56c4.19 0 5.47 1.35 5.47 5.79V18.3c0 2.21-.91 2.9-1.45 3.12-.53.21-1.63.33-3.08-1.21-.24-.26-.54-.39-.85-.37-.3.02-.59.18-.8.46l-1.01 1.35c-.54.7-1.3 1.1-2.08 1.1zm-3.92-4.42h.13c.74.04 1.44.43 1.91 1.06l1.01 1.35c.49.65 1.24.65 1.73 0l1.01-1.35c.48-.63 1.17-1.02 1.92-1.06.75-.04 1.48.27 2.02.85.76.81 1.25.91 1.43.84.24-.1.5-.68.5-1.72V7.04c0-3.61-.63-4.29-3.97-4.29h-7.56c-3.34 0-3.97.68-3.97 4.29V18.3c0 1.05.26 1.63.5 1.72.17.07.67-.03 1.43-.84.55-.55 1.22-.85 1.91-.85z"
                                ></path>
                                <path
                                  fill="#171717"
                                  d="M14.768 10.75h-5.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h5.5c.41 0 .75.34.75.75s-.34.75-.75.75z"
                                ></path>
                              </svg>
                              <span>{t("transactions")}</span>
                            </Link>
                          )}
                          <Link to="#" onClick={handleLogout}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#171717"
                                d="M15.24 22.27h-.13c-4.44 0-6.58-1.75-6.95-5.67-.04-.41.26-.78.68-.82.41-.04.78.27.82.68.29 3.14 1.77 4.31 5.46 4.31h.13c4.07 0 5.51-1.44 5.51-5.51V8.74c0-4.07-1.44-5.51-5.51-5.51h-.13c-3.71 0-5.19 1.19-5.46 4.39-.05.41-.39.72-.82.68a.751.751 0 01-.69-.81c.34-3.98 2.49-5.76 6.96-5.76h.13c4.91 0 7.01 2.1 7.01 7.01v6.52c0 4.91-2.1 7.01-7.01 7.01z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M14.88 12.75H2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h12.88a.749.749 0 110 1.5z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M12.65 16.1c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06L14.94 12l-2.82-2.82a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l3.35 3.35c.29.29.29.77 0 1.06l-3.35 3.35c-.15.15-.34.22-.53.22z"
                              ></path>
                            </svg>
                            <span>{t("logout")}</span>
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Container>
        </Navbar>
      </header>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {" "}
            <Image
              className="logo"
              src={
                window.location.origin +
                "/img/landing/efi-exchange-pro-logo.png"
              }
              type="image/png"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Navbar className="nav-mobile-menu">
            <Nav className="">
              <Link
                className={`nav-link ${
                  location.pathname === "/buy" ? "active" : ""
                }`}
                to="/buy"
              >
                {t("buy_swap_crypto")}
              </Link>
              <Link
                className={`nav-link ${
                  location.pathname === "/sell" ? "active" : ""
                }`}
                to="/sell"
              >
                {t("sell_crypto")}
              </Link>
              <Link
                className={`nav-link ${
                  location.pathname === "/trading" ? "active" : ""
                }`}
                to="/trading"
              >
                Trade
              </Link>
              <Link
                className={`nav-link ${
                  location.pathname === "/beneficiaries" ? "active" : ""
                }`}
                to="/beneficiaries"
              >
                {t("iban_transfer")}
              </Link>
              <Link
                className={`nav-link ${
                  location.pathname === "/page/about-us" ? "active" : ""
                }`}
                to="/page/about-us"
              >
                {t("about_us")}
              </Link>
              <Link
                className={`nav-link ${
                  location.pathname === "/page/help" ? "active" : ""
                }`}
                to="/page/help"
              >
                {t("help_center")}
              </Link>
            </Nav>
          </Navbar>
        </Offcanvas.Body>
      </Offcanvas>
      {logoutShow ? (
        <LogoutModal show={logoutShow} onHide={() => setLogoutShow(false)} />
      ) : null}
    </>
  );
};

export default NewLandingHeader;
