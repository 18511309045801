import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { buyTokensViewStart } from "../../store/actions/BuyAction";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import "./transaction.css";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import NoDataFound from "../helper/NoDataFound";

const BuyTransactionModal = (props) => {
  const { transactionId } = props;
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const t = useTranslation("transactions");
  const buyTokensView = useSelector((state) => state.buy.buyTokensView);

  useEffect(() => {
    dispatch(
      buyTokensViewStart({
        buy_transaction_id: transactionId,
      })
    );
  }, []);

  const formatNumber = (number) => {
    if (number) {
      return number
        .replace(/\s+/g, "")
        .split("")
        .reduce((seed, next, index) => {
          if (index !== 0 && index % 4 === 0) {
            seed += " ";
          }
          return seed + next;
        }, "");
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="view-modal-transaction"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Transaction Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {buyTokensView.loading ? (
            <>
              <div className="trans-view-grid-layout">
                <Skeleton height={270} />

                <Skeleton height={270} />
              </div>

              <Skeleton height={340} />
            </>
          ) : Object.keys(buyTokensView.data).length > 0 ? (
            <div className="transaction-view-card">
              {step == 1 ? (
                <>
                  <div className="trans-view-grid-layout">
                    <div className="transaction-view-box">
                      <div className="view-box-card">
                        <h5 className="view-label">Tokens Transferred</h5>
                        <p className="view-amount">
                          {
                            buyTokensView.data.buy_transaction
                              .to_amount_formatted
                          }
                        </p>
                      </div>
                      <div className="view-box-card">
                        <h5 className="view-label">Amount Paid</h5>
                        <p className="view-value">
                          {
                            buyTokensView.data.buy_transaction
                              .from_amount_formatted
                          }
                        </p>
                      </div>
                      <div className="view-box-card">
                        <h5 className="view-label">Status</h5>
                        <p
                          className={`status ${
                            buyTokensView.data.buy_transaction.status == 1
                              ? "succes"
                              : "denied"
                          } `}
                        >
                          {buyTokensView.data.buy_transaction.status_formatted}
                        </p>
                      </div>
                    </div>
                    <div className="transaction-view-box">
                      <div className="view-box-card">
                        <h5 className="view-label">Exchange Value</h5>
                        <p className="view-value">
                          {
                            buyTokensView.data.buy_transaction
                              .adjusted_exchange_rate_formatted
                          }
                        </p>
                      </div>
                      <div className="view-box-card">
                        <h5 className="view-label">Transaction Date</h5>
                        <p className="view-value">
                          {
                            buyTokensView.data.buy_transaction
                              .created_at_formatted
                          }
                        </p>
                      </div>
                      <div className="view-box-card">
                        <h5 className="view-label">Transaction reference id</h5>
                        {buyTokensView.data.buy_transaction.payment_id ? (
                          <>
                            <p className="view-value transaction-value">
                              {buyTokensView.data.buy_transaction.payment_id}

                              <CopyToClipboard
                                text={
                                  buyTokensView.data.buy_transaction.payment_id
                                }
                                onCopy={() =>
                                  getSuccessNotificationMessage(
                                    t("payment_id_was_copied_to_clipboard")
                                  )
                                }
                              >
                                <Button className="action-btn copy-btn item_center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#298BFF"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                  </svg>
                                </Button>
                              </CopyToClipboard>
                            </p>
                          </>
                        ) : (
                          "Transaction ref id not available"
                        )}
                      </div>
                      <div className="view-box-card">
                        <h5 className="view-label">Type</h5>
                        <p className="view-value">
                          {buyTokensView.data.buy_transaction.payment_type ==
                          "bank"
                            ? "Bank"
                            : buyTokensView.data.buy_transaction.payment_type ==
                              "card"
                            ? "Card"
                            : buyTokensView.data.buy_transaction.payment_type ==
                              "wallet"
                            ? "Wallet"
                            : "Not available"}
                        </p>
                      </div>
                      {buyTokensView.data.buy_transaction.payment_type ==
                        "card" && (
                        <div className="view-box-card">
                          <h5 className="view-label">Card details</h5>
                          <p className="view-value">
                            {
                              buyTokensView.data.buy_transaction
                                .user_card_number
                            }
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {buyTokensView.data.buy_transaction.payment_type ==
                    "bank" && (
                    <div className="view-bank-box">
                      <div className="view-bank-head">
                        <h2>Bank Details</h2>
                        <Button
                          className="action-btn link-btn item_center_x_5 "
                          onClick={() => setStep(2)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#298BFF"
                            viewBox="0 0 32 32"
                          >
                            <path d="M16 26.439c-9.823 0-15.612-9.298-15.854-9.693a.997.997 0 01-.124-.731.995.995 0 01.132-.744c.25-.396 6.246-9.71 15.845-9.71 9.823 0 15.612 9.298 15.854 9.693a.997.997 0 01.124.731.995.995 0 01-.132.744c-.25.397-6.246 9.71-15.845 9.71zM2.062 16.02C3.135 17.588 8.268 24.44 16 24.44c7.571 0 12.831-6.88 13.938-8.459-1.073-1.568-6.206-8.42-13.938-8.42-7.571 0-12.831 6.879-13.938 8.459zM16 21.701c-3.176 0-5.76-2.584-5.76-5.76s2.584-5.76 5.76-5.76 5.76 2.584 5.76 5.76-2.584 5.76-5.76 5.76zm0-9.519c-2.073 0-3.76 1.687-3.76 3.76s1.687 3.76 3.76 3.76 3.76-1.687 3.76-3.76-1.687-3.76-3.76-3.76z"></path>
                          </svg>
                          View Payment Proof
                        </Button>
                      </div>
                      <div className="view-bank-titles-card">
                        <div className="view-bank-titles-info">
                          <div className="view-bank-icons">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              enableBackground="new 0 0 512 512"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                data-original="#000000"
                              ></path>
                            </svg>
                          </div>
                          <div className="view-bank-info">
                            <p>
                              {
                                buyTokensView.data.buy_transaction
                                  ?.admin_bank_account?.bank_name
                              }
                            </p>
                          </div>
                        </div>
                        <div className="view-bank-titles-type">
                          <p className="bank-type">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="none"
                              viewBox="0 0 16 17"
                            >
                              <path
                                stroke="#252525"
                                strokeLinecap="round"
                                strokeWidth="2"
                                d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                              ></path>
                            </svg>
                            <span>
                              {
                                buyTokensView.data.buy_transaction
                                  ?.admin_bank_account?.account_holder_name
                              }
                            </span>
                          </p>
                        </div>
                      </div>
                      {props.show.from_currency !== "INR" ? (
                        <div className="view-bank-full-box">
                          <div className="view-bank-info-card">
                            <div className="view-bank-info-card-title">
                              <h3>Beneficiary</h3>
                            </div>
                            <div className="view-bank-grid-layout">
                              <div className="view-box-card">
                                <h5 className="view-label">IBAN No</h5>
                                <p className="view-value">
                                  {
                                    buyTokensView.data.buy_transaction
                                      ?.admin_bank_account?.bank_code
                                  }
                                </p>
                              </div>
                              <div className="view-box-card">
                                <h5 className="view-label">Bank swift code</h5>
                                <p className="view-value">
                                  {
                                    buyTokensView.data.buy_transaction
                                      ?.admin_bank_account
                                      ?.swift_code_correspondent_bank
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="view-bank-info-card">
                            <div className="view-bank-info-card-title">
                              <h3>Correspondent</h3>
                            </div>
                            <div className="view-bank-grid-layout">
                              <div className="view-box-card">
                                <h5 className="view-label">Swift Code</h5>
                                <p className="view-value">
                                  {
                                    buyTokensView.data.buy_transaction
                                      ?.admin_bank_account
                                      ?.swift_code_beneficiary_bank
                                  }
                                </p>
                              </div>
                              <div className="view-box-card">
                                <h5 className="view-label">Account Number</h5>
                                <p className="view-value">
                                  {formatNumber(
                                    buyTokensView.data.buy_transaction
                                      ?.admin_bank_account?.account_number
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="view-bank-full-box">
                          <div className="view-bank-info-card">
                            <div className="view-bank-info-card-title">
                              <h3>Beneficiary</h3>
                            </div>
                            <div className="view-bank-grid-layout">
                              <div className="view-box-card">
                                <h5 className="view-label">Account Number</h5>
                                <p className="view-value">
                                  {formatNumber(
                                    buyTokensView.data.buy_transaction
                                      ?.admin_bank_account?.account_number
                                  )}
                                </p>
                              </div>
                              <div className="view-box-card">
                                <h5 className="view-label">Ifsc No</h5>
                                <p className="view-value">
                                  {
                                    buyTokensView.data.buy_transaction
                                      ?.admin_bank_account?.bank_code
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <div className="view-bank-box">
                  <div className="view-bank-head">
                    {/* <h2>Bank Details</h2> */}
                    <Button
                      className="action-btn link-btn item_center_x_5 "
                      onClick={() => setStep(1)}
                    >
                      <svg
                        width="22"
                        height="18"
                        viewBox="0 0 22 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1.27L2.28 0L19 16.72L17.73 18L14.65 14.92C13.5 15.3 12.28 15.5 11 15.5C6 15.5 1.73 12.39 0 8C0.69 6.24 1.79 4.69 3.19 3.46L1 1.27ZM11 5C11.7956 5 12.5587 5.31607 13.1213 5.87868C13.6839 6.44129 14 7.20435 14 8C14.0005 8.34057 13.943 8.67873 13.83 9L10 5.17C10.3213 5.05698 10.6594 4.99949 11 5ZM11 0.5C16 0.5 20.27 3.61 22 8C21.1834 10.0729 19.7966 11.8723 18 13.19L16.58 11.76C17.9629 10.8034 19.0783 9.5091 19.82 8C19.0117 6.34987 17.7565 4.95963 16.1974 3.98735C14.6382 3.01508 12.8375 2.49976 11 2.5C9.91 2.5 8.84 2.68 7.84 3L6.3 1.47C7.74 0.85 9.33 0.5 11 0.5ZM2.18 8C2.98835 9.65013 4.24346 11.0404 5.80264 12.0126C7.36182 12.9849 9.16251 13.5002 11 13.5C11.69 13.5 12.37 13.43 13 13.29L10.72 11C10.0242 10.9254 9.37482 10.6149 8.87997 10.12C8.38512 9.62518 8.07458 8.97584 8 8.28L4.6 4.87C3.61 5.72 2.78 6.78 2.18 8Z"
                          fill="#298BFF"
                        />
                      </svg>
                      Hide Payment Proof
                    </Button>
                  </div>
                  <CustomLazyLoad
                    className="product-preview"
                    src={buyTokensView.data.buy_transaction.payment_file}
                    alt="image-preview"
                  />
                </div>
              )}
            </div>
          ) : (
            <NoDataFound />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BuyTransactionModal;
